<template>
  <div class="p-4">
    <camera-management />
    <!-- <main-page/> -->
  </div>
</template>

<script>
// import MainPage from './MainPage.vue';
import CameraManagement from './CameraManagement.vue';

export default {
  components: {
    // MainPage,
    CameraManagement
  }
};
</script>
