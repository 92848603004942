export const columns = [
  {
    title: 'Camera Name',
    dataIndex: 'camera_name',
    key: 'camera_name'
  },
  // {
  //   title: 'Device Name',
  //   dataIndex: 'device_name',
  //   key: 'device_name',
  // },
  {
    title: 'Status',
    key: 'status',
    dataIndex: 'status'
  },
  {
    title: 'Camera Type',
    key: 'camera_type',
    dataIndex: 'camera_type'
  },
  {
    title: 'IP Address',
    key: 'ip_address',
    dataIndex: 'ip_address'
  },
  {
    title: 'Device',
    key: 'device',
    dataIndex: 'device'
  },
  {
    title: 'Action',
    key: 'action',
    dataIndex: 'action'
  }
];

export const cameraTypes = ['USB', 'GIGE', 'REMOTE'];
export const cameraFields = {
  USB: ['index'],
  // DEPTH: ['index'],
  GIGE: ['index', 'vendor', 'cti_file', 'name'],
  REMOTE: ['ip', 'port', 'user', 'password', 'path']
};
export const cameraColumns = [
  {
    title: 'Device',
    dataIndex: 'device',
    key: 'device',
    width: '40%'
  },
  {
    title: 'Camera(s)',
    dataIndex: 'Cameras',
    key: 'cameras',
    width: '50%'
  },
  {
    title: 'Actions',
    dataIndex: 'operation',
    key: 'operation',
    width: '10%'
  }
];
