<template>
  <a-modal
    v-model:visible="showModal"
    title="Camera Configuration"
    :body-style="{ height: '50vh', overflowY: 'auto' }"
    ok-text="Save Changes"
    :ok-button-props="{ disabled: !hasNoEmptyFields() }"
    :confirm-loading="saveButtonIsLoading"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >
    <div class="d-flex align-items-center" style="gap:1em;">
      <a-select
        v-model:value="selectedDeviceName"
        placeholder="Select Device"
        class="w-100"
        :options="unConfiguredDevicesOption"
        show-search
        :filter-option="filterOption"
        @change="handleDeviceChange"
      >
        <!-- <a-select-option
          v-for="Device in unConfiguredDevices"
          :key="Device.Device.id"
          :value="Device.Device.display_name"
        >
          {{
            Device.Device.display_name
              ? Device.Device.display_name
              : Device.Device.Serial_number
          }}
        </a-select-option> -->
      </a-select>
      <a-button
        type="primary"
        class="float-right"
        :disabled="
          !selectedDevice || cameras.length > 1 || refreshButtonIsLoading
        "
        @click="addCamera"
      >
        Add Camera
      </a-button>
      <a-button
        type="primary"
        class="float-right"
        :disabled="!selectedDevice"
        :loading="refreshButtonIsLoading"
        @click="refreshDeviceAvailableCameras"
      >
        Refresh
      </a-button>
    </div>
    <a-divider />
    <!-- <span v-show="selectedDevice && !availableDeviceCameras"
      ><a-tag>Cameras are not available for this Device</a-tag></span
    > -->
    <div v-for="(camera, index) in cameras" :key="index">
      <a-card
        :title="'Camera ' + (index + 1)"
        size="small"
        :body-style="{ padding: '1em' }"
      >
        <template #extra>
          <a-button
            @click="removeCamera(index)"
            size="small"
            type="danger"
            style="float: right; text-align: center;"
          >
            X
          </a-button>
        </template>
        <a-space direction="vertical" class="w-100">
          <a-form-item
            :label="'Select Camera Type'"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }"
            style="margin-bottom: 0;"
          >
            <a-select
              v-model:value="cameras[index].type"
              :options="cameraTypes.map(el => ({ value: el, label: el }))"
              placeholder="Camera Type"
              class="w-100"
              show-search
              @change="updateCameraFields($event, index)"
            />
          </a-form-item>
          <template v-if="camera.type.toLowerCase() === 'remote'">
            <template
              v-for="(field, fieldIndex) in cameraFields[
                camera.type.toUpperCase()
              ]"
              :key="fieldIndex"
            >
              <a-form-item
                :label="capitalizeFirstLetter(field)"
                :label-col="{ span: 8 }"
                :wrapper-col="{ span: 16 }"
                style="margin-bottom: 0;"
              >
                <a-input
                  v-if="field !== 'password'"
                  v-model:value="camera[field]"
                  :name="'input' + index"
                  @input="updateFieldValue($event, index, field)"
                />
                <a-input
                  v-else
                  v-model:value="camera[field]"
                  type="password"
                  autocomplete="new-password"
                  :name="'password' + index"
                  @input="updateFieldValue($event, index, field)"
                />
              </a-form-item>
            </template>
          </template>
          <template v-else>
            <a-form-item
              v-show="camera.type"
              :label="'Select Camera'"
              :label-col="{ span: 8 }"
              :wrapper-col="{ span: 16 }"
              style="margin-bottom: 0;"
            >
              <a-select
                v-model:value="camera.name"
                :options="
                  availableDeviceCameras &&
                  availableDeviceCameras.available_cameras &&
                  availableDeviceCameras.available_cameras.cameras[
                    cameras[index].type.toLowerCase()
                  ]
                    ? availableDeviceCameras.available_cameras.cameras[
                        cameras[index].type.toLowerCase()
                      ].map(el => ({
                        value: el.name,
                        label: el.name,
                        disabled: isCameraSelected(el.name, el.index)
                      }))
                    : []
                "
                placeholder="Select a camera"
                class="w-100"
                show-search
                @change="selectAvailableCamera($event, index)"
              />
            </a-form-item>
          </template>
          <template v-if="camera.type === 'usb'">
            <a-checkbox
              v-show="isDepthCamera && camera.depth_config"
              style="margin-left: 145px;"
              :checked="
                camera.depth_config ? camera.depth_config.depth_enabled : false
              "
              @change="updateDepthCamera($event, camera)"
            >
              Enable Depth
            </a-checkbox>
          </template>
        </a-space>
      </a-card>
    </div>
  </a-modal>
  <a-row :gutter="[0, 16]">
    <a-col span="24">
      <a-input
        v-model:value="searchDevice"
        placeholder="Search Device"
        class="w-25"
      >
        <template #suffix>
          <search-outlined style="color: rgba(0, 0, 0, 0.45)" />
        </template>
      </a-input>
      <a-button type="primary" class="float-right" @click="addDevice">
        Add Device
      </a-button>
    </a-col>
    <a-col span="24">
      <a-table
        :columns="columns"
        :data-source="
          filteredConfiguredDevices.filter(
            record =>
              record.camera_config.cameras &&
              record.camera_config.cameras.length > 0
          )
        "
        :pagination="{ position: ['bottomCenter'] }"
        :scroll="{ x: 'max-content' }"
      >
        <template #bodyCell="{ column, record }">
          <template v-if="column.key === 'device'">
            {{
              record.Device.display_name
                ? record.Device.display_name
                : record.Device.Serial_number
            }}
          </template>
          <template v-else-if="column.key === 'cameras'">
            <span
              v-if="
                record.camera_config.cameras &&
                  record.camera_config.cameras.length > 0
              "
            >
              <a-tag
                v-for="(camera, index) in record.camera_config.cameras"
                :key="index"
                color="blue"
                ><a-popover arrow hover placement="top">
                  <template #content>
                    <p v-if="camera.name">
                      Name:
                      <a-tag color="processing">{{ camera.name }}</a-tag>
                    </p>
                    <p v-if="camera.type">
                      Type:
                      <a-tag color="processing">{{ camera.type }}</a-tag>
                    </p>
                    <!-- Conditionally display depth config if available -->
                    <p
                      v-if="
                        camera.depth_config &&
                          camera.depth_config.depth_enabled !== null &&
                          camera.depth_config.depth_enabled !== undefined
                      "
                    >
                      Depth Enabled:
                      <a-tag color="processing">{{
                        camera.depth_config.depth_enabled
                      }}</a-tag>
                    </p>
                    <p v-if="camera.type.toLowerCase() === 'remote'">
                      <!-- <br /> -->
                      IP Address:
                      <a-tag color="processing" class="mb-1">{{
                        camera.ip
                      }}</a-tag>
                      <br />
                      Path:
                      <a-tag color="processing" class="mb-1">{{
                        camera.path
                      }}</a-tag>
                      <br />
                      Port:
                      <a-tag color="processing" class="mb-1">{{
                        camera.port
                      }}</a-tag>
                      <br />
                      User:
                      <a-tag color="processing" class="mb-1">{{
                        camera.user
                      }}</a-tag>
                    </p>
                  </template>
                  {{ camera.type.toUpperCase() }}
                </a-popover>
              </a-tag>
            </span>
            <span v-else>No cameras configured</span>
          </template>
          <template v-else-if="column.key === 'operation'">
            <a-space>
              <a-button
                class="text-white"
                type="primary"
                @click="editDeviceCameras(record, index)"
              >
                Edit
              </a-button>
              <a-button type="primary" danger @click="showDeletePopup = true">
                <a-popconfirm
                  v-modal:visible="showDeletePopup"
                  title="Are you sure delete all Cameras"
                  ok-text="Delete"
                  cancel-text="Cancel"
                  @confirm="deleteCamera(record, index)"
                  @cancel="showDeletePopup = false"
                  >Delete
                </a-popconfirm>
              </a-button>
            </a-space>
          </template>
        </template>
      </a-table>
    </a-col>
  </a-row>
</template>
<script>
import { defineComponent } from 'vue';
import { SearchOutlined } from '@ant-design/icons-vue';
import CameraManagementService from 'src/services/cameraManagement';
import DeviceService from 'src/services/device';
import fcm from 'src/services/fcm';
import { cameraTypes, cameraColumns, cameraFields } from './config';

export default defineComponent({
  components: { SearchOutlined },
  inject: ['toast', 'deepClone'],
  setup() {
    return {
      columns: cameraColumns,
      cameraTypes,
      cameraFields
    };
  },
  data() {
    return {
      organization: localStorage.getItem('organization'),
      showModal: false,
      selectedDeviceName: null,
      selectedDevice: null,
      configuredDevices: [],
      unConfiguredDevices: [],
      unConfiguredDevicesOption: [],
      availableDeviceCameras: [],
      availableDeviceCameraOptions: [],
      canSaveChanges: false,
      cameras: [],
      searchDevice: '',
      newConfig: {
        device: null,
        cameras: []
      },
      isDepthCamera: false,
      saveButtonIsLoading: false,
      showDeletePopup: false,
      refreshButtonIsLoading: false,
      pollingInterval: null
    };
  },
  computed: {
    filteredConfiguredDevices() {
      if (!this.configuredDevices) {
        return [];
      }
      const searchLowerCase = this.searchDevice.toLowerCase().trim();

      return this.configuredDevices.filter(device =>
        device.Device.display_name
          ? device.Device.display_name.toLowerCase().includes(searchLowerCase)
          : device.Device.Serial_number
          ? device.Device.Serial_number.toLowerCase().includes(searchLowerCase)
          : false
      );
    }
  },
  watch: {
    unConfiguredDevices(Devices) {
      this.unConfiguredDevicesOption = Devices.map(el => ({
        value: el.Device.id,
        label: el.Device.display_name
          ? el.Device.display_name
          : el.Device.Serial_number
      }));
    },
    selectedDevice(value) {
      let deviceId = value?.Device?.id;
      this.availableDeviceCameras = null;
      if (deviceId) this.setAvailableCameras(deviceId);
    }
  },
  created() {
    this.setDeviceCameraConfig();
  },
  methods: {
    formatDateWithCurrentTime(timestamp) {
      const date = new Date(timestamp);

      // Extract the date and time components
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      // Create the formatted date-time string
      const formattedDateTime = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
      return formattedDateTime;
    },
    async setDeviceCameraConfig() {
      let unConfig = [];
      let config = [];
      const [
        error,
        data
      ] = await CameraManagementService.getDeviceCameraConfig();
      if (error) {
        console.log({ error });
        return;
      }
      data.forEach(item => {
        if (Object.keys(item.camera_config).length === 0) {
          unConfig.push(item);
        } else {
          config.push(item);
        }
      });
      config = config.map(entry => ({
        ...entry,
        camera_config: {
          cameras: entry.camera_config.cameras.map(camera => ({
            ...camera,
            type:
              camera.type && camera.type.toLowerCase() === 'geni'
                ? 'GIGE'
                : camera.type
          }))
        }
      }));
      this.unConfiguredDevices = unConfig;
      this.configuredDevices = config;
      // this.configuredDevices = data?.devices_with_camera_config;
      // this.unConfiguredDevices = data?.devices_without_camera_config;
    },
    async setAvailableCameras(deviceId) {
      const [error, data] = await CameraManagementService.getAvailableCameras(
        deviceId
      );
      if (error) {
        console.log({ error });

        return;
      }
      if (data) {
        if (data?.available_cameras?.cameras) {
          data.available_cameras.cameras = this.convertGeniToGige(
            data?.available_cameras?.cameras
          );
        }
      }
      this.availableDeviceCameras = data;
    },
    convertGeniToGige(input) {
      const convertKey = key => key.replace('geni', 'gige');

      const convertValue = value => {
        if (Array.isArray(value)) {
          return value.map(convertValue);
        } else if (typeof value === 'object' && value !== null) {
          return Object.fromEntries(
            Object.entries(value).map(([k, v]) => [
              convertKey(k),
              convertValue(v)
            ])
          );
        } else if (typeof value === 'string') {
          return value.toLowerCase() === 'geni' ? 'GIGE' : value;
        } else {
          return value;
        }
      };

      if (!input || typeof input !== 'object') {
        throw new Error('Invalid input. Please provide a valid object.');
      }

      const result = {};
      for (const [key, value] of Object.entries(input)) {
        result[convertKey(key)] = convertValue(value);
      }

      return result;
    },
    async refreshDeviceAvailableCameras() {
      this.refreshButtonIsLoading = true;
      const [
        error,
        data
      ] = await CameraManagementService.getDeviceAvailableCameras(
        this.selectedDevice.Device.Serial_number,
        false
      );
      if (data) {
        this.availableDeviceCameras = data;
        this.refreshButtonIsLoading = false;
        return;
      }
      this.refreshButtonIsLoading = false;
    },
    async sendSQSmessage(signal) {
      this.refreshButtonIsLoading = true;
      const payload = {
        data: 'None',
        type: signal,
        device_id: this.selectedDevice.Device.Serial_number,
        organization: this.organization,
        platform: 'portal',
        message_time: new Date().toISOString()
      };
      const [error, data] = await fcm.sendMessageToSQS(payload, false);
      if (error) {
        console.log(error);
        throw 'Error while sending sqs message';
      }
      console.log(Date.now());
      await new Promise(resolve => setTimeout(resolve, 1000));
      console.log(Date.now());
      this.refreshAvailableCameras();
    },
    async refreshAvailableCameras() {
      const startTime =
        this.availableDeviceCameras && this.availableDeviceCameras.last_modified
          ? this.availableDeviceCameras.last_modified
          : this.formatDateWithCurrentTime(new Date().toISOString());
      const pollingTimeout = 10000; // 10 seconds

      // Clear previous polling interval if any
      clearInterval(this.pollingInterval);

      const pollFunction = async () => {
        const [error, data] = await CameraManagementService.getAvailableCameras(
          this.selectedDevice.Device.id,
          false
        );
        // console.log(this.pollingInterval, startTime, data.last_modified);
        if (!error) {
          const lastModifiedChanged = data.last_modified > startTime;

          if (lastModifiedChanged) {
            this.availableDeviceCameras = data;

            clearInterval(this.pollingInterval);
            this.pollingInterval = null;
            this.refreshButtonIsLoading = false;
          }
        }
      };

      this.pollingInterval = setInterval(pollFunction, 1000);

      setTimeout(() => {
        clearInterval(this.pollingInterval);
        this.pollingInterval = null;
        this.refreshButtonIsLoading = false;
      }, pollingTimeout);
    },
    handleDeviceChange(value) {
      this.selectedDevice = this.unConfiguredDevices.find(
        device => device.Device.id === value
      );
    },
    addCamera() {
      this.cameras.push({ type: '', name: '' });
    },
    removeCamera(index) {
      this.cameras.splice(index, 1);
    },
    updateCameraFields(selectedType, index) {
      this.cameras[index]['type'] = selectedType;
      this.cameras[index]['name'] = '';
      if (selectedType.toLowerCase() !== 'remote') {
        this.availableDeviceCameraOptions = this.availableDeviceCameras.available_cameras.cameras[
          this.cameras[index].type.toLowerCase()
        ].map(el => ({
          value: el.name,
          label: el.name
        }));
        return;
      }
      delete this.cameras[index].name;
      this.cameras[index] = {};
      this.cameras[index]['type'] = selectedType;
      this.cameraFields[selectedType.toUpperCase()].forEach(field => {
        this.cameras[index][field] = ''; // Initialize or clear additional fields
      });
      selectedType = null;
    },
    updateFieldValue(value, index, field) {
      console.log('field updated');
      // console.log(
      //   'Field:',
      //   field,
      //   'Updated Value:',
      //   this.cameras[index][field]
      // );
    },
    selectAvailableCamera(value, index) {
      let selectedCameraObj = this.availableDeviceCameras.available_cameras.cameras[
        this.cameras[index].type.toLowerCase()
      ].find(
        camera =>
          camera.name === value &&
          !this.isCameraSelected(camera.name, camera.index)
      );

      this.cameras[index] = this.deepClone(selectedCameraObj);
      if (this.cameras[index] &&
          this.cameras[index].type &&
          this.cameras[index].type.toLowerCase() === 'usb')
        this.isDepthCamera =
          this.cameras[index].depth_config &&
          this.cameras[index].depth_config.depth_enabled
            ? true
            : false;

      // console.log(
      //   this.cameras,
      //   this.availableDeviceCameras.available_cameras.cameras[
      //     this.cameras[index].type.toLowerCase()
      //   ]
      // );
    },
    isCameraSelected(cameraName, cameraIndex) {
      return this.cameras.some(camera => {
        return camera.name === cameraName && camera.index === cameraIndex;
      });
    },
    async handleSubmit() {
      this.saveSelectedCameras({ cameras: this.cameras });
    },
    async handleCancel() {
      this.setDeviceCameraConfig();
      this.refreshButtonIsLoading = false;
      this.showModal = false;
    },
    async saveSelectedCameras(cameras, deviceSettingId = null) {
      cameras = {
        cameras: cameras.cameras.map(camera => {
          if (camera.type.toLowerCase() === 'gige') {
            return { ...camera, type: 'Geni' };
          } else if (camera.type.toLowerCase() === 'usb') {
            return { ...camera, type: 'usb' };
          } else if (camera.type.toLowerCase() === 'remote') {
            return { ...camera, type: 'remote' };
          }
          return camera;
        })
      };
      cameras.cameras.forEach(camera => {
        if (
          camera &&
          camera.type &&
          camera.type.toLowerCase() === 'remote' &&
          !('path' in camera)
        )
          camera['path'] = camera['path'] ? camera['path'] : '';
      });
      this.saveButtonIsLoading = true;
      const jsonObject = {
        camera_config: cameras.cameras.length > 0 ? cameras : {}
      };
      deviceSettingId = deviceSettingId
        ? deviceSettingId
        : this.selectedDevice.id;
      const res = await DeviceService.updateDeviceSettings(
        this.organization,
        deviceSettingId,
        jsonObject
      );
      if (res === 'error') {
        this.toast.error('Error occured while selecting cameras!', {
          timeout: 2000
        });
        return;
      }
      this.toast.success('Cameras updated successfully!', {
        timeout: 2000
      });
      this.setDeviceCameraConfig();
      this.saveButtonIsLoading = false;
      this.showModal = false;
    },
    deleteCamera(record, index) {
      if (
        record.camera_config &&
        record.camera_config.cameras &&
        record.camera_config.cameras.length > 0
      ) {
        this.saveSelectedCameras({ cameras: [] }, record.id);
      }
    },
    editDeviceCameras(record, index) {
      this.selectedDevice = record;
      this.selectedDeviceName = record.Device.display_name
        ? record.Device.display_name
        : record.Device.Serial_number;

      this.cameras = [...record.camera_config.cameras];

      this.$nextTick(() => {
        this.showModal = true;
      });
    },
    updateDepthCamera(event, camera) {
      camera.depth_config.depth_enabled = event.target.checked;
    },
    addDevice() {
      this.selectedDeviceName = null;
      this.selectedDevice = null;
      this.availableDeviceCameras = [];
      this.availableDeviceCameraOptions = [];
      this.cameras = [];

      this.showModal = true;
    },
    capitalizeFirstLetter(str) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },

    hasNoEmptyFields() {
      var tempCameras = JSON.parse(JSON.stringify(this.cameras));
      let emptyFields = tempCameras.every(camera => {
        if (camera && camera.type && camera.type.toLowerCase() === 'remote') {
          delete camera.path;
        }
        return Object.values(camera).every(
          value => value !== null && value !== undefined && value !== ''
        );
      });
      return emptyFields;
    },
    filterOption(input, option) {
      return option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    }
  }
});
</script>
